import React, { useMemo, useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';

import styles from './Header.module.scss';

const cx = classNames.bind(styles);

const Header = () => {
  const location = useLocation();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    setHidden(localStorage.getItem('@token') === null);
  }, [location]);

  const title = useMemo(() => {
    const { pathname } = location;

    if (pathname === '/') {
      return 'H2Logis';
    } else if (pathname === '/posts') {
      return '공지사항';
    } else if (pathname.startsWith('/posts/')) {
      return '공지사항 상세';
    } else if (pathname === '/tanks') {
      return '용기관리';
    } else if (pathname === '/tanks/edit') {
      return '임시 용기 등록';
    } else if (pathname === '/tanks/charge') {
      return '용기 충전';
    } else if (pathname.endsWith('/checklist')) {
      return '체크리스트 확인';
    } else if (pathname === '/orders') {
      return '주문배송';
    } else if (pathname.startsWith('/orders/')) {
      return '주문배송 상세';
    } else if (pathname === '/reclaims') {
      return '회수배송';
    } else if (pathname.startsWith('/reclaims/')) {
      return '회수배송 상세';
    } else if (pathname === '/settings') {
      return '설정';
    } else if (pathname === '/settings/password') {
      return '비밀번호 변경';
    } else if (pathname === '/settings/users') {
      return '사용자 관리';
    } else if (pathname === '/settings/users/new') {
      return '사용자 등록';
    } else if (pathname.startsWith('/settings/users/')) {
      return '사용자 상세';
    } else if (pathname === '/scanner') {
      return 'QR 코드 스캔';
    }
  }, [location]);

  if (hidden) {
    return null;
  }

  return (
    <div className={cx('container')}>
      <h1>{title}</h1>
    </div>
  );
};

export default Header;
